import ChevronDown from 'assets/chevronDown.svg';
import React from 'react';
import styles from './Dropdown.module.scss';

export type Option = { value: string; label: string };

type Props = {
  'aria-controls'?: string | undefined;
  'aria-label'?: string | undefined;
  options: Option[];
  setValue: (value: string) => void;
  value: string | undefined;
};

export const Dropdown: React.FC<Props> = ({
  options,
  setValue,
  value,
  ...props
}) => {
  return (
    <div className={styles.root}>
      <select
        className={styles.dropdown}
        aria-label={props['aria-label']}
        aria-controls={props['aria-controls']}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value + option.label}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronDown className={styles.icon} />
    </div>
  );
};
