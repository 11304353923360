import { Filter as FilterType } from '@yleisradio/areena-types';
import React from 'react';
import { Filter } from './Filter';
import { SelectedFilterOptions } from './SelectedFilterOptions';
import styles from './Filters.module.scss';

interface Props {
  filters: FilterType[];
  listContentId: string;
  selectFilterOption: (filterIndex: number, optionIndex: number) => void;
  selectedFilterOptions: SelectedFilterOptions;
}

export const Filters: React.FunctionComponent<Props> = ({
  filters,
  listContentId,
  selectFilterOption,
  selectedFilterOptions,
}) => {
  return (
    <div className={styles.filters}>
      {filters.map((f, i) => (
        <div key={i}>
          <Filter
            filter={f}
            listContentId={listContentId}
            selectOption={(optionIndex: number) =>
              selectFilterOption(i, optionIndex)
            }
            selectedOptionIndex={selectedFilterOptions[i] || 0}
          />
        </div>
      ))}
    </div>
  );
};
