import { Control } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { Controls } from 'components/Controls';
import React from 'react';
import styles from './ListHeader.module.scss';

type Props = {
  title: string | undefined;
  titleElementId: string | undefined;
  controls: Control[] | undefined;
};

export const ListHeader: React.FunctionComponent<Props> = ({
  title,
  titleElementId,
  controls = [],
}) => {
  if (!title && controls.length === 0) return null;

  return (
    <Container>
      <div className={styles.header}>
        {title ? (
          <h2 className={styles.title} id={titleElementId}>
            {title}
          </h2>
        ) : null}
        {controls.length > 0 && (
          <div className={styles.controls}>
            <Controls
              controls={controls}
              as="button"
              variation="text"
              size="xs"
              padding="none"
            />
          </div>
        )}
      </div>
    </Container>
  );
};
