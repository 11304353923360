import { ListStyle, Notification } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import {
  NotificationComponent,
  Notifications,
} from 'components/Notifications/Notifications';
import styles from './ListNotifications.module.scss';

type Props = {
  notifications: Notification[] | undefined;
  listStyle: ListStyle | undefined;
  notificationComponent?: ComponentType<NotificationComponent> | undefined;
};

export const ListNotifications: React.FunctionComponent<Props> = ({
  notifications,
  listStyle,
  notificationComponent,
}) => {
  const notificationStyle = !listStyle ? 'text' : 'primary';

  const className = classNames(
    styles.listNotifications,
    !listStyle && styles.text
  );

  if (notifications && notifications.length > 0) {
    return (
      <div className={className}>
        <Notifications
          notificationStyle={notificationStyle}
          notifications={notifications}
          notificationComponent={notificationComponent}
        />
      </div>
    );
  }
  return null;
};
