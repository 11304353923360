import React from 'react';
import styles from './ScrollButton.module.scss';
import Icon from 'assets/chevronRight.svg';
import classNames from 'classnames';

interface Props {
  direction: 'left' | 'right';
  hidden: boolean;
  onClick: () => void;
}

export const ScrollButton: React.FunctionComponent<Props> = ({
  direction,
  hidden,
  onClick,
}) => {
  return (
    <button
      aria-hidden={true}
      className={classNames(
        styles.button,
        direction === 'left' && styles.buttonLeft
      )}
      hidden={hidden}
      onClick={onClick}
      tabIndex={-1}
    >
      <Icon className={styles.icon} />
    </button>
  );
};
