import { Button } from 'components/Button';
import styles from './ScrollButton.module.scss';

type Props = {
  onClick: () => void;
  text: string;
};

const ScrollButton: React.FC<Props> = ({ onClick, text }) => (
  <div className={styles.scrollButtonWrapper}>
    <Button text={text} onClick={onClick} variant={'secondary'} />
  </div>
);

export default ScrollButton;
