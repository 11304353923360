import { Container } from 'components/Container';
import * as React from 'react';
import styles from './PlaceholderHeroCard.module.scss';
import { GridContainer, GridElement } from 'components/Grid';
import { ListStyle } from '@yleisradio/areena-types';
import { FC } from 'react';
import classNames from 'classnames';

type Props = {
  imageAspectRatio?: ListStyle['image'];
};

export const PlaceholderHeroCard: FC<Props> = ({ imageAspectRatio }) => {
  const hasSquareImage = imageAspectRatio === '1:1';

  const rootClasses = classNames(
    styles.root,
    hasSquareImage ? styles.rootWithSquareImage : styles.rootWithNoSquareImage
  );

  return (
    <div className={rootClasses}>
      <Container>
        <GridContainer>
          <GridElement mobile={2} mobileLandscape={0} />

          {hasSquareImage && (
            <GridElement mobile={8} mobileLandscape={4} desktop={6}>
              <div className={styles.image} />
            </GridElement>
          )}

          <GridElement mobile={2} mobileLandscape={0} />

          <GridElement mobile={12} mobileLandscape={8}>
            <div className={styles.textContent}>
              <div className={styles.highlightLabel} />
              <div className={styles.title} />
              <div className={styles.description} />
              <div className={styles.control} />
            </div>
          </GridElement>
        </GridContainer>
      </Container>
    </div>
  );
};
