import React from 'react';
import { DesktopDropdown } from './DesktopDropdown';
import styles from './Dropdown.module.scss';
import MobileDropdown from './MobileDropdown';
import { DropdownProps } from './types';

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  filter,
  listContentId,
  selectedOptionIndex,
  selectOption,
}) => {
  return (
    <div className="Dropdown">
      <div className={styles.mobile}>
        <MobileDropdown
          filter={filter}
          listContentId={listContentId}
          selectedOptionIndex={selectedOptionIndex}
          selectOption={selectOption}
        />
      </div>
      <div className={styles.desktop}>
        <DesktopDropdown
          filter={filter}
          listContentId={listContentId}
          selectedOptionIndex={selectedOptionIndex}
          selectOption={selectOption}
        />
      </div>
    </div>
  );
};
