import { Filter as FilterType } from '@yleisradio/areena-types';
import React from 'react';
import logger from 'services/logger';
import { Dropdown } from './Dropdown';

interface Props {
  filter: FilterType;
  listContentId: string;
  selectOption: (optionIndex: number) => void;
  selectedOptionIndex: number;
}

export const Filter: React.FunctionComponent<Props> = ({
  filter,
  listContentId,
  selectOption,
  selectedOptionIndex,
}) => {
  switch (filter.presentation) {
    case 'filter-dropdown':
      return (
        <Dropdown
          filter={filter}
          listContentId={listContentId}
          selectedOptionIndex={selectedOptionIndex}
          selectOption={selectOption}
        />
      );
    default: {
      logger.warn(`Unsupported filter presentation "${filter.presentation}"`);
      return (
        <Dropdown
          filter={filter}
          listContentId={listContentId}
          selectedOptionIndex={selectedOptionIndex}
          selectOption={selectOption}
        />
      );
    }
  }
};
