import { List as ListType } from '@yleisradio/areena-types';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import * as React from 'react';
import { ComponentType, useCallback, useState } from 'react';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import { ListLayoutProps } from '.';
import GridList from './GridList';
import { HeroList } from './HeroList';
import { HorizontalList } from './HorizontalList';
import styles from './List.module.scss';
import { VerticalList } from './VerticalList';
import { ListHeader } from './ListHeader';
import { ImpressionAnalyticsContextProvider } from 'contexts/ImpressionAnalyticsContext';
import { NotificationComponent } from 'components/Notifications';

function useListLayoutComponent(
  list: ListType
): React.ComponentType<ListLayoutProps> {
  if (list.style?.size === 'extra-large') return HeroList;

  switch (list.style?.layout) {
    case 'vertical':
      return VerticalList;
    case 'grid':
      return GridList;
    default:
      return HorizontalList;
  }
}

interface ListProps {
  list: ListType;
  listKey: string;
  index: number;
  notificationComponent?: ComponentType<NotificationComponent>;
}

export const List: React.FunctionComponent<ListProps> = ({
  list,
  listKey,
  index,
  notificationComponent,
}) => {
  const ListLayout = useListLayoutComponent(list);

  const [isHidden, setHiddenState] = useState(false);
  const hideList = useCallback(() => setHiddenState(true), []);

  const title = list.title && list.title.trim();
  const titleId = title ? sanitizeHtmlId(`${listKey}-title`) : undefined;

  if (isHidden) return null;

  return (
    <AnalyticsContextProvider context={list.analytics?.context?.comscore}>
      <ImpressionAnalyticsContextProvider
        uiElementPosition={index}
        uiElementType="list"
      >
        <section
          className={styles.list}
          aria-labelledby={titleId}
          data-testid="list"
        >
          <ListHeader
            title={title}
            titleElementId={titleId}
            controls={list.controls}
          />
          <ListLayout
            hideList={hideList}
            index={index}
            list={list}
            listKey={listKey}
            notificationComponent={notificationComponent}
          />
        </section>
      </ImpressionAnalyticsContextProvider>
    </AnalyticsContextProvider>
  );
};
