import { Notification } from '@yleisradio/areena-types';
import { useEffect } from 'react';

type Props = {
  /* Loading and error states have to be checked in addition to card count
     as useCards uses 0 as card count in loading and error states too. */
  cardCount: number;
  hasCardFetchErrored: boolean;
  hideList: () => void;
  isListLoadingFirstCards: boolean;
  notifications: Notification[] | undefined;
};

export function useHideEmptyList({
  cardCount,
  hasCardFetchErrored,
  hideList,
  isListLoadingFirstCards,
  notifications,
}: Props): void {
  const hasUiNotifications =
    !!notifications &&
    notifications.some((notification) => !!notification.uiMessage);
  const isTotalCardCountKnown =
    !isListLoadingFirstCards && !hasCardFetchErrored;
  const isListEmpty = isTotalCardCountKnown && cardCount === 0;

  useEffect(() => {
    if (isListEmpty && !hasUiNotifications) {
      hideList();
    }
  }, [hasUiNotifications, hideList, isListEmpty]);
}
