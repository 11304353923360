import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { prettyPath } from 'utils/url';

export type FilterOptions = {
  [listKey: string]: number[];
};

type QueryFilters = [string, string | string[] | undefined][];

const LIST_FILTER_KEY_PREFIX = 'list_filters_';

const hasOptions = (filterOptions: FilterOptions) =>
  Object.keys(filterOptions).length > 0;

const getFilterOptions = (
  queryFilters: QueryFilters
): FilterOptions | undefined => {
  const filterOptions: FilterOptions = queryFilters.reduce(
    (result, [key, filterOptions]) => {
      if (Array.isArray(filterOptions)) {
        const filterIntOptions = filterOptions.map((option: string) =>
          parseInt(option, 10)
        );
        return { ...result, [key]: filterIntOptions };
      }

      if (typeof filterOptions === 'string') {
        const filterIntOptions = parseInt(filterOptions, 10);
        return { ...result, [key]: [filterIntOptions] };
      }
      return result;
    },
    {}
  );

  return hasOptions(filterOptions) ? filterOptions : undefined;
};

export const useFilterQuery = (listIndex: number) => {
  const router = useRouter();

  const filterQuery = useMemo(
    () =>
      Object.entries(router.query).filter(([key]) =>
        key.includes(LIST_FILTER_KEY_PREFIX)
      ),
    [router.query]
  );

  const filterOptions = useMemo(
    () => getFilterOptions(filterQuery),
    [filterQuery]
  );

  const getFilterQueryOptions = () =>
    filterOptions?.[`${LIST_FILTER_KEY_PREFIX}${listIndex}`];

  const setFilterQueryOptions = useCallback(
    (filterQueryOptions: number[]) => {
      const updatedQuery = {
        ...router.query,
        [`${LIST_FILTER_KEY_PREFIX}${listIndex}`]: `${filterQueryOptions[listIndex]}`,
      };

      router.replace(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        prettyPath(router.asPath, updatedQuery),
        { scroll: false, shallow: true }
      );
    },
    [listIndex, router]
  );

  return {
    getFilterQueryOptions,
    setFilterQueryOptions,
  };
};
