import * as ToggleGroup from '@radix-ui/react-toggle-group';
import React from 'react';
import { AlternateButton } from 'components/AlternateButton';
import styles from './DesktopDropdown.module.scss';
import { DropdownProps } from 'components/List/Filters/Filter/Dropdown/types';

export const DesktopDropdown: React.FunctionComponent<DropdownProps> = ({
  filter,
  listContentId,
  selectedOptionIndex,
  selectOption,
}) => {
  return (
    <ToggleGroup.Root
      aria-controls={listContentId}
      aria-label={filter.title}
      className={styles.list}
      onValueChange={(value) => {
        selectOption(Number(value));
      }}
      role="radiogroup"
      type="single"
      value={String(selectedOptionIndex)}
    >
      {filter.options.map((o, i) => {
        const isSelected = i === selectedOptionIndex;
        return (
          <ToggleGroup.Item asChild key={i} value={String(i)}>
            <AlternateButton
              isSelected={isSelected}
              size="large"
              text={o.title}
            />
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};
