import { useCallback } from 'react';
import { useFilterQuery } from './useFilterQuery';

type SetStateCallback = (current: number[] | undefined) => number[] | undefined;

type SetState = (setter: SetStateCallback) => void;

type HookReturn = [number[] | undefined, SetState];

export const useFilterQueryState = (listIndex: number): HookReturn => {
  const filterQuery = useFilterQuery(listIndex);
  const currentFilterOptions = filterQuery.getFilterQueryOptions();

  const setState = useCallback(
    (set: SetStateCallback) => {
      const updatedFilterOptions = set(filterQuery.getFilterQueryOptions());
      if (updatedFilterOptions) {
        filterQuery.setFilterQueryOptions(updatedFilterOptions);
      } else {
        return currentFilterOptions;
      }
    },

    [currentFilterOptions, filterQuery]
  );

  return [currentFilterOptions, setState];
};
