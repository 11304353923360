import { ListStyle, Pointer } from '@yleisradio/areena-types';
import { Card } from 'components/Card';
import { useCards } from 'hooks/useCards';
import { useProgress } from 'hooks/useProgress';
import React from 'react';
import { getCardKey, getHistoryLabelsForCard } from 'utils/card';
import { ListItemMicrodata } from '../../ListItemMicrodata';
import { Observable as ImpressionObservable } from 'contexts/ImpressionAnalyticsContext/Observable';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { idFromPointer } from 'utils/pointer';

type Props = {
  cardImageSizes: string;
  hidden?: boolean;
  listStyle: ListStyle | undefined;
  pageIndex: number;
  pageKey: string;
  pageSize: number;
  source: Pointer;
};

export const CardPage: React.FunctionComponent<Props> = ({
  cardImageSizes,
  hidden,
  listStyle,
  pageIndex,
  pageKey,
  pageSize,
  source,
}) => {
  const { cards, analyticsContext } = useCards({
    source,
    pageIndex,
    pageSize,
  });

  const { historyEntries } = useProgress(cards);

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      {cards.map((card, cardIndex) => {
        const cardKey = getCardKey(card, pageKey);

        return (
          <ListItemMicrodata
            key={cardKey}
            hidden={hidden}
            itemIndex={pageIndex * pageSize + cardIndex}
            pointer={card.pointer}
          >
            <ImpressionObservable
              yleId={idFromPointer(card.pointer) || undefined}
              itemPosition={cardIndex}
            >
              <Card
                card={card}
                cardKey={cardKey}
                listStyle={listStyle}
                sizes={cardImageSizes}
                historyLabels={getHistoryLabelsForCard(card, historyEntries)}
              />
            </ImpressionObservable>
          </ListItemMicrodata>
        );
      })}
    </AnalyticsContextProvider>
  );
};
