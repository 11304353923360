import { Filter } from '@yleisradio/areena-types';
import logger from 'services/logger';
import { SelectedFilterOptions } from 'components/List/Filters/SelectedFilterOptions';
import { useFilterQueryState } from './useFilterQueryState';

const getDefaultApiOptions = (filters: Filter[]): number[] => {
  return filters.reduce<number[]>((result, filter, filterIndex) => {
    const option = filter.options.findIndex((option) => !!option.default);
    if (option) {
      result[filterIndex] = option;
    }
    return result;
  }, []);
};

function getFilterParameters(
  filters: Filter[],
  selectedFilterOptions: SelectedFilterOptions
): Record<string, string> {
  return Object.entries(selectedFilterOptions).reduce<Record<string, string>>(
    (result, [filterIndexString, filterOption]) => {
      const filterIndex = Number(filterIndexString);
      return {
        ...result,
        ...filters[filterIndex]?.options[filterOption]?.parameters,
      };
    },
    {}
  );
}

function areDefaultOptionsSelected(
  filters: Filter[],
  selectedFilterOptions: SelectedFilterOptions
): boolean {
  return filters.every((filter, filterIndex): boolean => {
    const defaultOptionIndex = filter.options.findIndex((o) => !!o.default);
    return (
      defaultOptionIndex >= 0 &&
      defaultOptionIndex === selectedFilterOptions[filterIndex]
    );
  });
}

type HookParameters = {
  listIndex: number;
  filters: Filter[];
  resetListPaging: () => void;
};
type HookReturn = {
  selectedFilterOptions: SelectedFilterOptions;
  selectFilterOption: (filterIndex: number, optionIndex: number) => void;
  filterParameters: Record<string, string>;
  filterDefaultsSelected: boolean;
  filterStateKey: string;
};

export function useFilters({
  listIndex,
  filters,
  resetListPaging,
}: HookParameters): HookReturn {
  const [selectedFilterOptionsFromQuery, setState] =
    useFilterQueryState(listIndex);
  const selectedFilterOptions =
    selectedFilterOptionsFromQuery ?? getDefaultApiOptions(filters);

  const selectFilterOption = (filterIndex: number, optionIndex: number) => {
    setState((current) => {
      const filter = filters[filterIndex];
      if (filter) {
        if (
          optionIndex >= 0 &&
          optionIndex < filter.options.length &&
          optionIndex !== current?.[filterIndex]
        ) {
          resetListPaging();
          const updated = [...(current || [])];
          updated[filterIndex] = optionIndex;
          return updated;
        }
      } else {
        logger.error(
          new RangeError(`Filter of index ${filterIndex} does not exist`)
        );
      }
      return current;
    });
  };

  const filterParameters = getFilterParameters(filters, selectedFilterOptions);

  const filterDefaultsSelected = areDefaultOptionsSelected(
    filters,
    selectedFilterOptions
  );

  const filterStateKey = Object.values(selectedFilterOptions).join('-');

  return {
    selectedFilterOptions,
    selectFilterOption,
    filterParameters,
    filterDefaultsSelected,
    filterStateKey,
  };
}
