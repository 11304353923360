import { HeroCard } from 'components/HeroCard';
import { PlaceholderHeroCard } from 'components/PlaceholderHeroCard';
import { useCards } from 'hooks/useCards';
import * as React from 'react';
import { getCardKey } from 'utils/card';
import { ListLayoutProps } from '../ListLayoutProps';
import { ListNotifications } from '../ListNotifications';
import { Observable as ImpressionObservable } from 'contexts/ImpressionAnalyticsContext/Observable';
import { idFromPointer } from 'utils/pointer';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';

export const HeroList: React.FunctionComponent<ListLayoutProps> = ({
  list,
  listKey,
}) => {
  const { cards, loading, notifications, analyticsContext } = useCards({
    source: list.source,
    pageIndex: 0,
    pageSize: 1,
  });

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <ListNotifications listStyle={list.style} notifications={notifications} />

      {cards.map((card, index) => {
        const key = getCardKey(card, listKey);

        return (
          <ImpressionObservable
            key={key}
            yleId={idFromPointer(card.pointer) || undefined}
            itemPosition={index}
          >
            <HeroCard
              card={card}
              cardKey={key}
              key={key}
              imageAspectRatio={list.style?.image}
            />
          </ImpressionObservable>
        );
      })}

      {loading && <PlaceholderHeroCard imageAspectRatio={list.style?.image} />}
    </AnalyticsContextProvider>
  );
};
