import { Dropdown } from 'components/Dropdown';
import { DropdownProps } from 'components/List/Filters/Filter/Dropdown/types';
import styles from './MobileDropdown.module.scss';

export const MobileDropdown: React.FunctionComponent<DropdownProps> = ({
  filter,
  listContentId,
  selectOption,
  selectedOptionIndex,
}) => {
  return (
    <div className={styles.root}>
      <Dropdown
        aria-label={filter.title}
        aria-controls={listContentId}
        options={filter.options.map((option, optionIndex) => ({
          value: optionIndex.toString(),
          label: option.title,
        }))}
        setValue={(value) => {
          selectOption(Number(value));
        }}
        value={selectedOptionIndex.toString()}
      />
    </div>
  );
};
